<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
            name="fromDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
            name="toDate"
          />
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('hideZeroBalances')">
            <b-form-checkbox
              v-model="selectedItem.hideZeroBalances"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.groupId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="accountGroups"
              field="select"
              name="accountGroups"
              :options="groups"
              label="arabicName"
              @change="getAccounts"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.id"
            :dir="isRight ? 'rtl' : 'ltr'"
            :label="isRight ? 'arabicName' : 'englishName'"
            :options="mainAccounts"
            label-text="accountName"
            field="select"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="branchesIdList"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="branches"
              field="select"
              name="branches"
              multiple
              :options="branches"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          v-if="
            this.profile.permissions.indexOf('viewShowAllBranchesInReports') >
              -1 || this.profile.isAdmin
          "
        >
          <b-form-group :label="$t('ShowAllBranches')">
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" type="submit" variant="primary">
            {{ $t('printDetails') }}
          </b-button>
          <b-button class="mx-1" @click="print('total')" variant="primary">
            {{ $t('printTotal') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups';

export default {
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  mixins: [reportMixin],
  data() {
    return {
      mainAccounts: [],
      groups: [],
      levels: [],
      branchesIdList: [],
      selectedItem: {
        id: null,
        code: null,
        groupId: null,
        level: null,
        fromDate: this.today,
        toDate: this.today,
        groupName: null,
        hideZeroBalances: false,
        branchIdList: null,
      },
    };
  },
  beforeMount() {
    this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}'
        ? this.currentYear.startDate
        : this.company.fiscalYearStart;
    this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}'
        ? this.currentYear.endDate
        : this.company.fiscalYearEnd;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
    this.getAccounts();
    this.getItems();
  },
  watch: {
    'selectedItem.groupId'() {
      this.getAccounts();
    },
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getItems() {
      this.get({ url: 'AccountGroups' }).then((data) => {
        this.groups = data;
      });
    },
    getAccounts() {
      let filter = '';
      if (this.selectedItem.groupId) {
        filter = `&groupId=${this.selectedItem.groupId}`;
      }
      if (this.selectedItem.branchId) {
        filter += `&branchId=${this.selectedItem.branchId}`;
      }
      if (filter) {
        this.get({ url: `accounts?type=Main${filter}` }).then((data) => {
          data.forEach((element) => {
            element.arabicName = `${element.code} - ${element.arabicName}`;
            element.englishName = `${element.code} - ${element.englishName}`;
          });
          this.mainAccounts = data;
        });
      } else {
        this.get({ url: 'accounts?type=Main' }).then((data) => {
          data.forEach((element) => {
            element.arabicName = `${element.code} - ${element.arabicName}`;
            element.englishName = `${element.code} - ${element.englishName}`;
          });
          this.mainAccounts = data;
        });
      }
    },
    getMaxLevel() {
      this.get({
        url: `accounts/max-level?branchId=${this.currentBranch.id}`,
      }).then((data) => {
        if (data > 0) {
          for (var i = 1; i <= data; i++) {
            this.levels.push({ id: i, arabicName: i, englishName: i });
          }
          return;
        }
        this.levels = [];
      });
    },
    prepareItemForPrint() {
      const allbranches = reportParameters.find(
        (par) => par.id === 'allBranches'
      );
      this.selectedItem.branchName = this.selectedItem.ShowAllBranches
        ? this.isRight
          ? allbranches.arabicName
          : allbranches.englishName
        : this.isRight
        ? this.currentBranch.arabicName
        : this.currentBranch.englishName;
    },
    print(type) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      let repName = '';
      this.prepareItemForPrint();
      const branchesList = [];
      this.branchesIdList.forEach((element) => {
        branchesList.push(element.id);
      });
      this.selectedItem.branchIdList = branchesList.toString();
      if (this.selectedItem.branchIdList === '') {
        this.selectedItem.branchIdList = this.currentBranch.id;
      }
      if (this.selectedItem.ShowAllBranches === true) {
        this.selectedItem.branchIdList = null;
      }
      if (this.selectedItem.groupId) {
        this.selectedItem.groupName = this.groups.filter(
          (x) => x.id === this.selectedItem.groupId
        )[0].arabicName;
      } else {
        this.selectedItem.groupName = null;
      }
      if (this.selectedItem.id) {
        this.selectedItem.code = this.mainAccounts.filter(
          (x) => x.id === this.selectedItem.id
        )[0].code;
        this.selectedItem.mainAccountName = this.mainAccounts.filter(
          (x) => x.id === this.selectedItem.id
        )[0].arabicName;
      } else {
        this.selectedItem.code = null;
        this.selectedItem.mainAccountName = null;
      }
      if (type === 'total') {
        repName = 'AccountStatementWithMainTotals-ar';
        this.level = null;
      } else {
        repName = 'AccountStatementWithMainDetails-ar';
        this.level = null;
      }
      if (this.selectedItem.fromDate > this.selectedItem.toDate) {
        this.doneAlert({
          text: this.$t('fromDateAfterToDate'),
          type: 'warning',
        });
        return;
      }
      this.printReport(repName, this.selectedItem);
    },
  },
};
</script>
