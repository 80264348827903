<template>
  <b-row>
    <b-col
      md="3"
      class="mt-4"
    >
      <b-card>
        <ul class="list-unstyled">
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <li
            v-for="x in reports"
            v-if="isEnabled(x)"
            :key="x.title"
            v-permission="x.permission"
            :v-bind:class="{ 'btn-flat-primary': selectedReport === x.title }"
            class="p-1 nav-item-report cursor-pointer border-bottom"
            @click="
              () => {
                selectedReport = x.title;
                selected = x;
              }
            "
          >
            <feather-icon
              :icon="x.icon"
              size="20"
              class="mr-75"
            />
            <span>{{ $t(x.title) }}</span>
          </li>
        </ul>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card-title
        v-if="selectedReport"
        class="p-0 m-0 text-primary text-darken-5"
      >
        <b-row class="p-0">
          <b-col
            md="12"
            class="text-center"
          >
            <span>{{ $t(selectedReport) }}</span>
          </b-col>
        </b-row>
      </b-card-title>
      <hr
        v-if="selectedReport"
        class="border-info border-darken-2"
      >
      <taxesReport
        v-if="selectedReport === 'additionalValueTaxes'"
        :type="selected"
      />
      <collectionVouchers
        v-if="selectedReport === 'collectionVouchers' || selectedReport === 'paymentVouchers'"
        :type="selected"
      />
      <banksAccountStatement
        v-if="selectedReport === 'bankAccountStatment'"
        :type="selected"
      />
      <safeAccountStatement
        v-if="selectedReport === 'safeAccountStatement'"
        :type="selected"
      />
      <incomeTransaction
        v-if="selectedReport === 'incomeTransaction' || selectedReport === 'expenseTransaction'"
        :type="selected"
      />
      <accountStatementMainReport v-if="selectedReport === 'accountStatementMain'" />
      <accountStatementDetailsReport
        v-if="selectedReport === 'accountStatementDetails' || selectedReport === 'trialBalance'"
        :type="selected"
      />
      <incomeStatementReport v-if="selectedReport === 'incomeStatementReport'" />
      <balanceSheetReport
        v-if="selectedReport === 'balanceSheetReport'"
        :type="selected"
      />
      <supplierExpensesReport
        v-if="selectedReport === 'supplierExpensesReport'"
        :type="selected"
      />
      <costCenterReport
        v-if="selectedReport === 'costCenterReport'"
        :type="selected"
      />
    </b-col>
  </b-row>
</template>

<script>
import { accountsReports } from '@/libs/acl/Lookups';
import taxesReport from '@/pages/Settings/Report/taxes.vue';
import accountStatementDetailsReport from '@/pages/Settings/Report/AccountStatementDetailsReport.vue';
import accountStatementMainReport from '@/pages/Settings/Report/AccountStatementMainReport.vue';
import incomeStatementReport from '@/pages/Settings/Report/IncomeStatementReport.vue';
import collectionVouchers from '@/pages/Settings/Report/collectionVouchers.vue';
import banksAccountStatement from '@/pages/Settings/Report/bankAccountStatment.vue';
import safeAccountStatement from '@/pages/Settings/Report/safeAccountStatement.vue';
import incomeTransaction from '@/pages/Settings/Report/incomeTransaction.vue';
import balanceSheetReport from '@/pages/Settings/Report/BalanceSheetReport.vue';
import supplierExpensesReport from '@/pages/Settings/Report/supplierExpensesReport.vue';
import costCenterReport from '@/pages/Settings/Report/CostCenterReport.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    accountStatementDetailsReport,
    accountStatementMainReport,
    incomeStatementReport,
    collectionVouchers,
    banksAccountStatement,
    safeAccountStatement,
    incomeTransaction,
    taxesReport,
    balanceSheetReport,
    supplierExpensesReport,
    costCenterReport
  },
  mixins: [
  reportMixin,
  ],
  data() {
    return {
      menu: accountsReports,
      reports: [],
      selectedReport: '',
      selected: {},
      isEnabledAccounts: true,
    };
  },
  computed: {},
  mounted() {
    this.reports = this.menu;
    this.selectedReport = 'accountStatementDetails'
    if (this.profile.permissions.length > 0) {
                this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
                this.selected = this.reports.find(obj => obj.title === this.selectedReport);
            }
            if (localStorage.accountsEnabled) {
                this.isEnabledAccounts = JSON.parse(localStorage.accountsEnabled)
            }
    //  if (this.profile.permissions.length > 0) {
    //    this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
    // }
    // this.selected = this.reports.find(obj => obj.title === this.selectedReport);
    // if (localStorage.accountsEnabled) {
    //             this.isEnabledAccounts = JSON.parse(localStorage.accountsEnabled)
    //           }
  },
  methods: {
    isEnabled(item) {
                if (item.isEnabled && (item.isEnabled === this.isEnabledAccounts)) {
                    return true
                }
                if (item.isEnabled === undefined) {
                    return true
                }
            }
  },
};
</script>

<style scoped>
.nav-item-report:hover {
  margin-left: 5px;
  transition: all 0.5s ease;
}
</style>
